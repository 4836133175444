<div class="text-center p-3">
    <h1>Useful Links</h1>

</div>

<div class="p-3 text-center">

    <h5>Related Clubs and Organizations</h5>
    <ul>
        <li>
            <a href="https://www.carpathiaclub.com/">Carpathia Club, Sterling Heights</a>
        </li>

        <li>
            <a href="http://www.germanamericanmetrodetroit.org/">German American Cultural Center, Detroit</a>
        </li>

        <li>
            <a href="http://www.edelweiss-detroit.org/">GTEV Edelweis Detroit (Bavarian Cultural Club)</a>
        </li>

        <li>
            <a href="http://www.gpwa-usa.com/">German American Professional Women Association</a>
        </li>
        <li>
            <a href="https://www.gaccmidwest.org/en/">German American Chamber of Commerce - Midwest</a>
        </li>
        <li>
            <a href="http://macombsymphony.org/">Macomb Symphony Orchestra</a>
        </li>
        <li>
            <a href="https://www.michigan.org/property/comic-opera-guild">Michigan Comic Opera Guild, Ann Arbor</a>
        </li>
        </ul>



    <h5>Austria - Links to Government and USA Presence</h5>
    <ul>
        <li>
            <a href="https://www.austria.org/">Austrian Embassy, Washington D.C.</a>
        </li>

        <li>
            <a href="https://www.bmeia.gv.at/gk-new-york/">Austrian Consulate General, New York</a>
        </li>

        <li>
            <a href="https://www.bmeia.gv.at/gk-los-angeles/">Austrian Consulate General, Los Angeles</a>
        </li>

        <li>
            <a href="https://www.embassy-worldwide.com/embassy/austrian-consulate-in-detroit-the-united-states/?__cf_chl_jschl_tk__=f1ee0b414f3eb6d904490f9b0e40927f43e672dd-1616332105-0-AbmSGKz5D8jta9z3MlJ6_-DmKSuq77fCyHMyyQEOxgKSCo3STfYwnI2Tsu4go2xBU3T8jlEBKzkrtqYqfTu9YgRP2PWClhLFDNiOr0kts48rA7Odb2JMvO-g97hsS4Nf6C0yb_ufuKX4xrWAzKq8n-kyocG7mCyc2SJ1Bz6fhfThsawQPSMVZmtGIXzBKmuEqjJpMf2beC1ml8k0gkk8A__eTcsqGHYMC6KykyIgiad4KcJjGsr1YDmRcZzEkNiUn92qRwJsLSpZQhOHYKXMvS8M54_IRc422ZUMVsjFERMA7gBSoRVsylxn1IZCHwsAh7JN9KkWFCrWThQJf_gCQvF1LndSHultQrsRW6Jv-J9uSDRpzbxtck55CVgi_Dcw9avsT_tJKhy1NAagzmpYCag">Honorary Consulate of Austria, Southeastern Michigan</a>
        </li>

        <li>
            <a href="https://www.bundeskanzleramt.gv.at/">Austrian Government</a>
        </li>
                    
        <li>
            <a href="https://www.weltbund.at/">Austrians abroad/Weltbund</a>
        </li>
    </ul>


    <h5>Austria - General References</h5>
    <ul>
        <li>
            <a href="https://www.oesterreich.com/de/">Austria Local Portal</a>
        </li>

        <li>
            <a href="https://b2b.austria.info/us/">Austria Tourism Office</a>
        </li>

        <li>
            <a href="https://austria-forum.org/af/AEIOU">Austria Forum</a>
        </li>

        <li>
            <a href="https://www.herold.at/">Austrian Telephone Book</a>
        </li>
        </ul>

        <h5>Austria - News</h5>
        <ul>
            <li>
                <a href="https://apa.at/">Austria Presse Agentur</a>
            </li>
    
            <li>
                <a href="https://orf.at/">Österreichischer Rundfunk</a>
            </li>
    
            <li>
                <a href="https://www.diepresse.com/">Die Presse (Vienna Daily Newspaper)</a>
            </li>
    
            <li>
                <a href="https://www.derstandard.at/ ">Der Standard (Wiener Tageszeitung)</a>
            </li>
            <li>
                <a href="https://www.kleinezeitung.at/ ">Kleine Zeitung (Grazer Tageszeitung)</a>
            </li>
            <li>
                <a href="https://www.thelocal.at/">Austrian and European News</a>
            </li>
            <li>
                <a href="https://onlineradiobox.com/at/">Austrian Radio Stations</a>
            </li>
            <li>
                <a href="https://wochenpostusa.com/">Nordamerikanische Wochenpost</a>
            </li>
    </ul>
    <h5>Austria - Culture and History</h5>
    <ul>
        <li>
            <a href="https://en.wikipedia.org/wiki/Portal:Austria">Everything Austria (Wikipedia Portal)</a>
        </li>

        <li>
            <a href="https://acfny.org/">Austrian Cultural Forum, NYC</a>
        </li>

        <li>
            <a href="https://www.visitingvienna.com/entertainment/events/opernball/">Wiener Opernball (Vienna Opera Ball)</a>
        </li>

        <li>
            <a href="https://en.wikipedia.org/wiki/Viennese_waltz">Wiener Walzer (Viennese Waltz)</a>
        </li>
        <li>
            <a href="http://ingeb.org/Volksong.html">Deutsche Volkslieder (German Folk Songs)</a>
        </li>
        </ul>

        <h5>Austria - Culinary</h5>
    <ul>
        <li>
            <a href="https://www.gutekueche.at/rezepte">Austrian Recipes/Gourmet Portal</a>
        </li>

        <li>
            <a href="https://www.kulinarisches-erbe.at/">Kulinarisches Erbe, Österreich</a>
        </li>

        <li>
            <a href="https://at.gaultmillau.com/">Top Restaurants, Austria - Active Search</a>
        </li>

        <li>
            <a href="https://www.austrianwine.com/">Austrian Wine</a>
        </li>
        <li>
            <a href="https://www.steirische-spezialitaeten.at/">Steirische Spezialitäten</a>
        </li>
        <li>
            <a href="https://schmankalan.at/">Schmankalan aus der Kärntner Küche</a>
        </li>
        </ul>


       

</div>

