<h1 class="text-center heading">Event Calendar</h1>
<div class="text-center">
    <h3 class="red">Annual Events</h3>
    <br>
    <p>
        Mitgliederversammlung am zweiten Mittwoch jeden Monats (mit Ausnahme Juli, August, Dezember) 
    </p>
    <p>
        Membership meetings the second Wednesday of every month (except July, August, December)
    </p>
    <p>
        8:00 PM
    </p>
    <p>
        Carpathia Hall, 38000 Utica Road, Sterling Heights 
    </p>
    <br>
    <p class="red">
        Wahlen zum Vorstand: Mitgliederversammlung im März
    </p>
    <p class="red">Elections to the Board: Membership Meeting in March</p>
</div>

<div class="table-responsive my-3 container">
    <table class="table table-bordered">
        <thead>
          <tr id="heading">
            <th scope="col">Date</th>
            <th scope="col">Event</th>
            
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>
          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>
          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>

          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>

          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>
          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>

          <tr>
            <td>dd.mm.yyyy</td>
            <td>event name</td>
            
          </tr>
   

        </tbody>
      </table>
      </div>

<iframe class="text-center container d-flex justify-center mb-4"
    src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%23ffffff&amp;ctz=Europe%2FVienna&amp;src=ZjVzbnQ1MmowdThnMWk0OGplOTlucWY5c2tAZ3JvdXAuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&amp;src=ZW4uYXVzdHJpYW4jaG9saWRheUBncm91cC52LmNhbGVuZGFyLmdvb2dsZS5jb20&amp;color=%234285F4&amp;color=%237986CB"
    style="border:solid 1px #777" width="800" height="600" frameborder="0" scrolling="no"></iframe>