<section id="thehero">
    <div class="the-inner">
        <div class="container">
            <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                    <h1 class="flag"><b>AUSTRIAN SOCIETY - <span class="blue">DETROIT</span></b></h1>
                    <div>
                        <h1><b> <span class="verein"> Verein der Österreicher</span></b></h1>
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center heroSub">
                    <div>
                        <p>gegründet 1932
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>

<body>

    <div class="tcontainer">
        <div class="ticker-wrap">
            <div class="ticker-move">
                <!-- <div class="ticker-item">GENERAL MEMBERSHIP MEETINGS: Thursday March 10th & Thursday April 14, 2022</div> -->
                <!-- <div class="ticker-item"><a href="../../assets/2022-Waldfest_Flyer-02.pdf" target="_blank"><b>WALDFEST
                            June 26, 2022.</b> Gates open from 10am-10pm. Soccer games start at 9am and 12 noon. Opening
                        Ceremony at 2:45pm </a></div> -->

                <div class="ticker-item"><a href="../../assets/24-Mar Newsltr.pdf" target="blank"> Newsletter March
                        2024</a></div>
                <div class="ticker-item"><a href="../../assets/2024-Waldfest_Flyer-02.pdf" target="blank"> Waldfest at Austrian Park, June 30 2024, noon to 8pm</a></div>
                <div class="ticker-item"><a href="../../assets/Golf Outing 2024.pdf" target="blank">Ski Club Golf Outing at Stony Creek Park, Sept. 22, 2024</a></div>
                <div class="ticker-item"><a href="../../assets/Golf sponsor form_24_01.pdf" target="blank">Golf Sponsorship</a></div>
                <div class="ticker-item"><a href="../../assets/Golf sponsor form_24_02.pdf" target="blank">Golf Sponsorship Form</a></div>
                
                <div class="ticker-item">Future Membership meetings at the GACC Hall every 2nd Tuesday of the month,
                    starting at 7:30pm</div>
                <!-- <div class="ticker-item">Membership Meetings: Every 2nd Tuesday of the month 7:30pm-9:30pm at
                    GACC Clubhouse</div> -->
                <div class="ticker-item">See <a routerLink="/membership" routerLinkActive="active">Membership
                        Section</a> for updates on annual dues</div>
                <!-- <div class="ticker-item">Skiing Weekend in March 2022 -- CANCELLED -->
                <!-- <div class="ticker-item"></div> -->
            </div>
        </div>
    </div>


    <div class="text-center text pl-5 pr-5 pt-3 my-4 container border border-danger">
        <div class="table-responsive my-3">
            <h3>Upcoming Events</h3>
            <table class="table table-hover">
                <thead>
                    <tr>
                        <th scope="col">Date (MM/DD/YYYY)</th>
                        <th scope="col">Time</th>
                        <th scope="col">Description</th>
                        <th scope="col">Additional Info</th>


                    </tr>
                </thead>
                <tbody>



                    <!-- <tr>
                        <td>Feb 26, 2023</td>
                        <td>9:00am - 4:30pm</td>
                        <td><a href="../../assets/2023 Feb26  Flyer_MtHolly-01.pdf" target="_blank">Austrian Ski Club
                                Outing at
                                Mt. Holly</a></td>
                        <td>Weather/snow conditions permitting<br> See <a
                                href="../../assets/2023 Feb26  Flyer_MtHolly-01.pdf" target="_blank">flyer</a> for more
                            details</td>

                    <tr> -->



                    <tr>
                        <td>June  30, 2024</td>
                        <td>12noon - 8pm</td>
                        <td>Waldfest<a href="../../assets/2024-Waldfest_Flyer-02.pdf" target="_blank"> click to open
                            flyer</a></td>
                        <td>Austrian Park</td>

                    <tr>

                        
                    <tr>
                        <td>Sept  22, 2024</td>
                        <td>7:15am check-in start, 8am shotgun start</td>
                        <td>Golf Outing<a href="../../assets/Golf Outing 2024.pdf" target="_blank"> click to open
                            flyer</a></td>
                        <td>Stony Creek Park</td>

                    <tr>
                    
                    <tr>
                        <td>Every 2nd Tuesday of the month</td>
                        <td>7:30pm</td>
                        <td>Membership Meetings</td>
                        <td>GACC Clubhouse 40450 Hayes Rd</td>

                    <tr>






                        <!-- <tr>
                        <td>Sept 11, 2022</td>
                        <td>Check-in start 7:15am, Shotgun start 8:15am</td>
                        <td>Golf Outing - <a href="../../assets/Golf2022_R1.pdf" target="_blank">click to open
                                flyer for registration & payment info</a></td>
                        <td>Stony Creek Metro Golf Course</td>
                    </tr> -->

                        <!-- <tr>
                        <td></td>
                        <td></td>
                        <td>Sponsorship - Golf Outing</td>
                        <td><a href="../../assets/Golf sponsor info_web.jpg">Sponsor Info</a><br><a
                                href="../../assets/Golf sponsor order_web.jpg">Sponsor Order</a></td>
                    </tr> -->



                        <!-- <tr>
                    <th scope="row">dd.mm.yyyy</th>
                    <td>hh:mm</td>
                    <td><a routerLink="/events">Name of event</a></td>

                </tr> -->
                </tbody>
            </table>
        </div>
    </div>


    <div class="container">
        <div class="text-center text pl-5 pr-5 pt-3 my-3">
            <h5>Der Zweck dieses Vereins ist, die <span class="red">Sitten</span> und Gebräuche der alten <span
                    class="red">Heimat</span>, deren Gesänge und Volkstänze aufrecht
                zu erhalten und auf das beste zu <span class="red">pflegen</span>; ferner den Frohsinn und die <span
                    class="red">Gemütlichkeit</span> sowie echte Freundschaft zu
                hegen und sich gegenseitig beizustehen.
            </h5>
            <br>
            <hr>
        </div>

        <div class="text-center text english pl-5 pr-5 mb-3">
            <h5>The purpose of this Society is to <span class="red">preserve</span> and practice the customs and <span
                    class="red">traditions</span> of the homeland, its songs
                and folk dances with the highest possible standards, to promote <span class="red">good cheer</span>,
                fellowship and genuine <span class="red">friendship</span>,
                and to assist one another.</h5>
        </div>

        <div class="text-center py-3">
            <img class="hero" src="../../assets/img/wappen.png" alt="">
        </div>
    </div>



</body>