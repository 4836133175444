<body>
  <div class="text-center my-3">
    <h1>Become a Member!</h1>
    <p>To become a member of the Austrian Society, please fill out the membership application below</p>
  </div>

  <div class="text-center my-3">
    <h4> <a href="../../assets/membership_form.pdf" target="_blank">Membership Application</a></h4>
  </div>


  <div class="container text-center my-5">
    <h4>
      Membership dues</h4>
    <table class="table table-hover">

      <tbody>
        <tr>
          <td>Family</td>
          <td>$50.00/year</td>
        </tr>
        <tr>
          <td>Single</td>
          <td>$30.00/year</td>
        </tr>
        <tr>

          <td>Age 65+ and a member for 5 years </td>
          <td>$20.00/year</td>

        </tr>
      </tbody>
    </table>

  </div>

  <div class="container text-center py-3 my-3 background">
    <h2 class="text-white">Coming Soon: Pay with PayPal</h2>
    <br>
    <div class="container text-center">
      <!-- PayPal Logo -->
      
          <img
                src="https://www.paypalobjects.com/webstatic/mktg/logo/pp_cc_mark_74x46.jpg" border="0"
                alt="PayPal Logo">
        
      <!-- PayPal Logo -->

     </div>

    <!-- PayPal form that links to my grubes gmail account -->
<div class="pt-2 text-white text-center">
  <!-- <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
    <input type="hidden" name="cmd" value="_xclick">
    <input type="hidden" name="business" value="grubes@gmail.com">
    <input type="hidden" name="lc" value="US">
    <input type="hidden" name="item_name" value="Membership">
    <input type="hidden" name="button_subtype" value="services">
    <input type="hidden" name="no_note" value="0">
    <input type="hidden" name="currency_code" value="USD">
    <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_SM.gif:NonHostedGuest">
    <table>
    <tr><td><input type="hidden" name="on0" value="Membership options">Membership options</td></tr><tr><td><select name="os0">
      <option value="Family">Family $35.00 USD</option>
      <option value="Single">Single $20.00 USD</option>
      <option value="Age 65+ & member for 5 years">Age 65+ & member for 5 years $12.00 USD</option>
    </select> </td></tr>
    </table>
    <input type="hidden" name="option_select0" value="Family">
    <input type="hidden" name="option_amount0" value="35.00">
    <input type="hidden" name="option_select1" value="Single">
    <input type="hidden" name="option_amount1" value="20.00">
    <input type="hidden" name="option_select2" value="Age 65+ & member for 5 years">
    <input type="hidden" name="option_amount2" value="12.00">
    <input type="hidden" name="option_index" value="0">
    <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_buynow_SM.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!">
    <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1">
    </form>
     -->
</div>
<!-- PayPal form that links to my grubes gmail account -->
  </div>


  <div class="container text-center border border-danger py-3 my-3">
    <h2>Pay with check
    </h2>
    
    <p>Checks to be made out to: Austrian Society</p>
    <p>Mail to:<br>
      Ms. Karen Childs <br>
      53476 Robinhood Dr. <br>
      Shelby Twp, MI 48315</p>


  </div>
  <div>

  </div>
</body>