<body>
    <div class="container border border-danger rounded p-3">
    <div class="container text-center my-3">
        <h1>Rental - Austrian Park</h1>
    </div>

    <div class="container">
        <p>
            Bookings for Calendar year 2023 are available to any individual, group or club.<br><br>
            Membership to the Austrian Society is no longer required since the party renting the Park and Clubhouse is
            responsible for their own Liability Insurance and also any other licenses or public fees, if applicable.<br><br>

            Please direct inquiries about available dates to rent the Park to Karen Childs at (586)202-0427 or <a href="mailto:aussocmsec@yahoo.com">aussocmsec@yahoo.com</a> <br><br>

            Any communication by regular mail, for example signed Rental Agreement, Insurance references, checks or
            similar, should be mailed to:<br>
          
        </p>
    </div>

    <div class="container text-center">
        Karen Childs<br>
        Secretary, Austrian Society<br>
        53476 Robinhood Dr<br>
        Shelby Twp. MI 48315<br>
        </div>
</div>

<div class="container text-center mt-5">
    <h3>Rental Fees - Austrian Park</h3>
    <div class="my-2">
        Click <a href="#">here</a> to view the Rental Contract form
      </div>

    <table class="table table-bordered">
        <caption>Please note that a deposit of $200.00 (typically) is required to firm up a booking for park rental.</caption>
        
        <tbody>
          <tr>
            <td>A</td>
            <td>Austrian Society, Ski Club or Soccer Club Committee Members who are active in the welfare of the Austrian Society</td>
            <td>Park & Clubhouse</td>
            <td>$300.00</td>
          </tr>
          <tr>
            <td>B</td>
            <td>Members with a minimum of 5 years membership and significantly active in the welfare of the Austrian Society</td>
            <td>Park & Clubhouse</td>
            <td>$300.00</td>
          </tr>
          <tr>
            <td>C</td>
            <td>Small groups or companies up to 250 people</td>
            <td>Park & Clubhouse</td>
            <td>$900.00</td>
          </tr>
          <tr>
            <td>D</td>
            <td>Large groups or companies up to 500 people</td>
            <td>Park & Clubhouse</td>
            <td>$1,750.00 <br> $2,000.00 including Kitchen and Bar access</td>
          </tr>
          <tr>
            <td>E</td>
            <td>Ethnic Groups, GACC - 500 to 800 people</td>
            <td>Park & Clubhouse</td>
            <td>$1,750.00 <br> $2,000.00 including Kitchen and Bar access</td>
          </tr>
          <tr>
            <td>F</td>
            <td>Large groups or companies - 500 to 1000 people </td>
            <td>Park & Clubhouse</td>
            <td>$1,750.00 <br> $2,000.00 including Kitchen and Bar access</td>
          </tr>
        </tbody>
      </table>

      
</div>

</body>