import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-waldfestpics', //update this component name when copy/pasting into new component//
  templateUrl: './waldfestpics.component.html',//update this component name when copy/pasting into new component//
  styleUrls: ['./waldfestpics.component.css']//update this component name when copy/pasting into new component//
})
export class WaldfestpicsComponent { //update this component name when copy/pasting into new component//
  currentIndex: any = -1;
  showFlag: any = false;
  
  imageObject: Array<object> = [
    {
      image: '../../assets/img/waldfest/2019/pic1.jpg',
      thumbImage: '../../assets/img/waldfest/2019/pic1.jpg',
      title: 'Waldfest 2019'
  }, 
  {
    image: '../../assets/img/waldfest/2019/pic2.jpg',
    thumbImage: '../../assets/img/waldfest/2019/pic2.jpg',
    title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic3.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic3.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic4.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic4.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic5.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic5.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic6.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic6.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic7.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic7.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic8.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic8.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic9.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic9.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic10.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic10.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic11.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic11.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic12.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic12.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic13.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic13.jpg',
  title: 'Waldfest 2019'
}, 
{
  image: '../../assets/img/waldfest/2019/pic14.jpg',
  thumbImage: '../../assets/img/waldfest/2019/pic14.jpg',
  title: 'Waldfest 2019'
}


];

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
