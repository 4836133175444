<div class="text-center p-3">
    <h1>Sports</h1>
    <hr>
</div>

<div class="text-center container border p-4 my-3">
    <div class="text-center photo">
        <img src="../../assets/img/skiclub.jpg" alt="">
    </div>

    <div class="text-center p-3 ski">
        <h2 class="blue">Austrian Ski Club</h2>
        <div>
            <p>"Land der Berge..." - "Land of Mountains..." with these words the Austrian National Anthem starts.
                Austrians and the members of our society almost by nature are enthusiastic skiers. Small wonder that the
                members of the society for years wanted a Ski Club as a sub-group. In 1968 Fritz Stockinger, himself a
                master skier, took the initiative. Together with some other ski enthusiasts he formed the Austrian Ski
                Club and became its first president. Fritz held that post for 15 years and in 1983 was named honorary
                president. Helmut Böhm (10 years) and Edmund Kirkman (8 years) succeded Fritz as presidents.<br><br>
                <b>The main focus of the Ski Club is to arrange for ski lessons for our youngsters.</b><br><br> The club
                is fortunate
                to have a number of qualified instructors to help in the program. But grown ups are cordially invited to
                join us on Sundays at the local ski hill, Mount Holly. <b>As a group we enjoy special reduced rates for
                    the
                    lift tickets.</b> We also rent a special room for the members of our ski club where everybody can
                leave
                their belongings while enjoying the slopes and warm up after skiing. For many years ski trips to Boyne
                Country were the highlight of the ski season. Ski races to determine the club champions of various age
                groups were held on the last day of the trip. On evenings the group got together to sing German songs
                and exchange the stories of the day.
            </p>

            <!-- <button class="btn btn-primary" href="#">More Info...</button> -->
        </div>
        <div class="pt-2">
            <img src="../../assets/img/skiclub_badge.jpg" alt="">
            <h4 class="blue">Ski Club officers for 2020</h4>
            <p><span class="red">President:</span> Chris Rampin</p>
            <p><span class="red">Vice President:</span> Herbert Gruber</p>
            <p><span class="red">Secretary:</span> Christoph Kreiter</p>
            <p><span class="red">Treasurer:</span> Irene Novak</p>
            <p><span class="red">Trustees:</span> Karl Nagel, Ethan Novak, Dave Nowak, Bridget Staruch</p>

        </div>
    </div>
</div>

<div class="text-center container border p-4 my-3">
    <div class="text-center photo">
        <img src="../../assets/img/golf.png" alt="">
    </div>

    <div class="text-center p-3">
        <h2>Annual Golf Outing</h2>
        <div>
            <p>The Austrian Ski Club is also very active during the summer months. Many of our skiers have discovered
                golf and the club arranges for golf outings crowned by the annual golf championship tournament in
                September. Every event and tournament ends - how else could it be - with a "gemütliches" get together
                with good food and drink and singing of songs from our home countries.</p>

            <!-- <button class="btn btn-primary" href="#">More Info...</button> -->
        </div>
    </div>
</div>