import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-newsarchive',
  templateUrl: './newsarchive.component.html',
  styleUrls: ['./newsarchive.component.css']
})
export class NewsarchiveComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
