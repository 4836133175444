<body>
    


<div class="text-center p-3">
    <h1>Our History </h1>
</div>

<div class="text-center photo">
    <img src="../../assets/img/soccer_historical.jpg" alt="">
</div>

<div class="container p-3 text-center">
    <h6>"1932 is generally mentioned as the year the Austrian Society was founded. But our roots go back 7 years more to
        1925, and a meeting place called Bundesheim - later called Schiller Hall on St. Aubin and Gratiot Avenue. There
        a table group, " A Tischgesellschaft" met under the name of Vindobona, Latin name of Vienna. Its members: Rudolf
        Lachner - President, Gottfried Wanneck, Franz Schauburger, Max Eidenbock, Joseph Hitch and Anton Markel Sr. In
        1928 Vindobona joined the GBU #721 and had the following members: E. Redderer - President, Anton Markel Sr., C.
        Elgarth, Karl Schuster, Gustav Schuster, Hermann Strelle, Franz J. Nothaft, V. Stering, R. Morche. This small
        group later broke away from the GBU #721 and called themselves the Austrian Society and 4 years later, after we
        had grown in numbers, we officially registered.
        Our members came chiefly from the old Austrian-Hungarian Empire where each country had its own tongue, but
        German was the parliamentary language. Even today you'll find many of our members to be multilingual."<br><br>

        So wrote our founding member Franz J. Nothaft in 1982. On March 2, 1932, when the Austrian Society of Detroit
        was registered, the membership had swollen to 80 members. Karl Schuster was elected the first president of the
        society. The club was very active and organized a wide variety of events: Weinlesefeste, Bauernbälle,
        Alpenkränzchen, Volksfeste. In many of them the Schuhplattler, a good sized group within the club, were the main
        attraction with their traditional dances. During the war years the activities were scaled back but the members
        still met on a regular basis.<br><br>

        The first Viennese Strauss Ball - the first two years the event was named Viennese Evening (Wiener Abend) - took
        place in 1937. It was not a very elegant affair - the tables were covered with white paper and the drinks were
        enjoyed out of paper cups and the aroma of sauerkraut filled the air - but the ball was an immediate success.
        After several moves to larger facilities the Viennese Strauss Ball finally found its home at the Riverview Ball
        Room of Cobo Center. Over the years it developed into the elegant Ball of today, reminiscent of the great balls
        of Vienna . During the second world war the balls were cancelled but as of this writing we are already working
        on the 57th edition of the Viennese Strauss Ball to be held in February 2004.<br><br>

        Our records show that in 1960 the traditional "Dirndlball" was held in the Griechenhalle and that our choir, at
        that time named "Alpenland", was a major attraction of the event. Three years later, in 1963, the Dirndlball was
        renamed to Evening in Vienna ("Ein Abend in Wien"); it is still a much loved part of our activities
        traditionally held on the first Saturday of November. The Evening in Vienna is above all known for good music,
        the "Alt-Wiener-Parade" and last but not least the home made Austrian tortes.<br> <br>

        For years Leo Polleiner, president of our society for many years and later named Honorary President, pushed the
        idea of us owning our own facility. Eddy Kluger found this beautiful 10 acre property "in the middle of nowhere"
        on Hayes Road between 25 and 26 Mile Road and Leo's dream finally came to fruition. Eddy was for many years the
        "Parkverwalter" (followed by Horst Griesser and Rudi Walter) and a program to really make this our home was
        started. Hundreds of fir trees were planted on a little hill, a soccer field was established and a small club
        house built. On weekends members and their families met for leasure and games; some even planted some vegetables
        and tomatoes.<br><br>

        The membership decided in 1983 to move the traditional Waldfest, which had been held at the Kolping Park, to the
        Austrian Park. The board, led by president Josef Hirmann, went to work immediately. Many members helped to pour
        the first concrete dance floor right next to the stand of fir trees on the little hill. All the rest of the park
        was put into immaculate shape and the first Waldfest in our park, in July 1984, was a great success. The dance
        floor was covered by a tent, picknick tables were arranged underneath the fir trees. The success led to the
        enlargement of the dance floor and the construction of a permanent roof over it. The Stockinger family donated
        the steel beams for the structure and the hard work of all the members made this huge undertaking possible.
        Every year more guests came to our Waldfest and every year, led by president Max Mader now, new additions were
        made in our park, water wells were drilled. Rudi Eschbach suggested in 1989 that we build a clubhouse, kitchen
        and bar next to the dance floor. Rudi also committed to bear half of the cost of the new building. Plans were
        made, funds were raised and in the spring of 1990 the actual building was started. Again our membership came
        through: not only were all the necessary funds donated but also members came to work in large numbers to help
        build our club house. In August 1990 we celebrated the opening of the new facility with a festive mass with the
        Andorfer Choir from Austria performing.</h6>


</div>
</body>