import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sb17',
  templateUrl: './sb17.component.html',
  styleUrls: ['./sb17.component.css']
})
export class Sb17Component {
  currentIndex: any = -1;
  showFlag: any = false;
  
  imageObject: Array<object> = [
    {
      image: '../../assets/img/straussball/2017/sb_2017_1.jpg',
      thumbImage: '../../assets/img/straussball/2017/sb_2017_1.jpg',
      title: 'Straussball 2017'
  }, {
      image: '../../assets/img/straussball/2017/sb_2017_2.jpg',
      thumbImage: '../../assets/img/straussball/2017/sb_2017_2.jpg'
  }, {
    image: '../../assets/img/straussball/2017/sb_2017_3.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_3.jpg',
    title: 'Straussball 2017'
  }, {
    image: '../../assets/img/straussball/2017/sb_2017_4.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_4.jpg',
    title: 'Straussball 2017'
  },{
    image: '../../assets/img/straussball/2017/sb_2017_5.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_5.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_6.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_6.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_7.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_7.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_8.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_8.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_9.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_9.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_10.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_10.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_11.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_11.jpg',
    title: 'Straussball 2017'
  },
  {
    image: '../../assets/img/straussball/2017/sb_2017_12.jpg',
    thumbImage: '../../assets/img/straussball/2017/sb_2017_12.jpg',
    title: 'Straussball 2017'
  }

];

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
