<html>
    <body class="text-center">
        <h1 class="text-center">Personal Histories</h1>
        <hr>
<DIV>
<h2>Herbert Gruber</h2>
<img class="profile" src="../../assets/img/profile_gruber.jpg" alt="">
</DIV>
<hr>
<DIV class="bg-secondary">
    <h2 class="text-white py-3">Günther Bräuner</h2>
    <img class="profile pb-3" src="../../assets/img/profile_brauner.png" alt="">
    </DIV>

    </body>
</html>




