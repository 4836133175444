<div class="text-center p-3">
    <h1>Photo Gallery</h1>
  
  </div>
  <div class="container">
    <h5>Austrian Society Gallery</h5>
    <div class="group-2">
      <div>
        <img class="thumb m-2" *ngFor="let img of imageObject; let i = index" [src]="img.thumbImage || img.posterImage"
          (click)="showLightbox(i)" />
      </div>
    </div>
  
  
    <ng-image-fullscreen-view [images]="imageObject" [show]="showFlag"
      (close)="closeEventHandler()"></ng-image-fullscreen-view>
  
  
  
  </div>
