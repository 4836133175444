import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';
import { HomeComponent } from './home/home.component';
import { HistoryComponent } from './history/history.component';
import { EventsComponent } from './events/events.component';
import { SportsComponent } from './sports/sports.component';
import { ParkComponent } from './park/park.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { GalleryComponent } from './gallery/gallery.component';
import { LinksComponent } from './links/links.component';
import { FooterComponent } from './footer/footer.component';
import { ContactComponent } from './contact/contact.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CommitteeComponent } from './committee/committee.component';
import { MembersComponent } from './members/members.component';
import { MembershipComponent } from './membership/membership.component';
import { NewsarchiveComponent } from './newsarchive/newsarchive.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { ParkrentalComponent } from './parkrental/parkrental.component';
import { Sb17Component } from './sb17/sb17.component';
import { Sb18Component } from './sb18/sb18.component';
import { Sb19Component } from './sb19/sb19.component';
import { WaldfestpicsComponent } from './waldfestpics/waldfestpics.component';
import { SkiclubpicsComponent } from './skiclubpics/skiclubpics.component';
import { AussocpicsComponent } from './aussocpics/aussocpics.component';

@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    HistoryComponent,
    EventsComponent,
    SportsComponent,
    ParkComponent,
    NewsletterComponent,
    GalleryComponent,
    LinksComponent,
    FooterComponent,
    ContactComponent,
    CalendarComponent,
    CommitteeComponent,
    MembersComponent,
    MembershipComponent,
    NewsarchiveComponent,
    ParkrentalComponent,
    Sb17Component,
    Sb18Component,
    Sb19Component,
    WaldfestpicsComponent,
    SkiclubpicsComponent,
    AussocpicsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgImageFullscreenViewModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
