import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aussocpics',
  templateUrl: './aussocpics.component.html',
  styleUrls: ['./aussocpics.component.css']
})
export class AussocpicsComponent {
  currentIndex: any = -1;
  showFlag: any = false;
  
  imageObject: Array<object> = [
    {
      image: '../../assets/img/mainclub/pic1.jpg',
      thumbImage: '../../assets/img/mainclub/pic1.jpg',
      title: 'Austrian Society'
  },
  {
    image: '../../assets/img/mainclub/pic2.jpg',
    thumbImage: '../../assets/img/mainclub/pic2.jpg',
    title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic3.jpg',
  thumbImage: '../../assets/img/mainclub/pic3.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic4.jpg',
  thumbImage: '../../assets/img/mainclub/pic4.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic5.jpg',
  thumbImage: '../../assets/img/mainclub/pic5.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic6.jpg',
  thumbImage: '../../assets/img/mainclub/pic6.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic7.jpg',
  thumbImage: '../../assets/img/mainclub/pic7.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic8.jpg',
  thumbImage: '../../assets/img/mainclub/pic8.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic9.jpg',
  thumbImage: '../../assets/img/mainclub/pic9.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic10.jpg',
  thumbImage: '../../assets/img/mainclub/pic10.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic11.jpg',
  thumbImage: '../../assets/img/mainclub/pic11.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic12.jpg',
  thumbImage: '../../assets/img/mainclub/pic12.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic13.jpg',
  thumbImage: '../../assets/img/mainclub/pic13.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic14.jpg',
  thumbImage: '../../assets/img/mainclub/pic14.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic15.jpg',
  thumbImage: '../../assets/img/mainclub/pic15.jpg',
  title: 'Austrian Society'
},
{
  image: '../../assets/img/mainclub/pic16.jpg',
  thumbImage: '../../assets/img/mainclub/pic16.jpg',
  title: 'Austrian Society'
}
];

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
