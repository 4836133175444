<h1 class="text-center">Committee and Board Members</h1>
<div class="container">
<div class="table-responsive my-3">
    <table class="table table-hover">
        <thead>
          <tr id="heading">
            <th scope="col">Position</th>
            <th scope="col">First name</th>
            <th scope="col">Last name</th>
            <th scope="col">Email</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row">President</th>
            <td>Günther</td>
            <td>Bräuner</td>
            <td><a href="mailto: braeuners@gmail.com">braeuners@gmail.com</a></td>
          </tr>
          <tr>
            <th scope="row">First Vice President</th>
            <td>Andy</td>
            <td>Griesser</td>
            <td><a href= "mailto:gnc3333@aol.com">gnc3333@aol.com</a></td>
          </tr>
          <tr>
            <th scope="row">Second Vice President</th>
            <td>Horst</td>
            <td>Griesser</td>
            <td><a href="mrhans1@aol.com">mrhans1@aol.com</a></td>
          </tr>

          <tr>
            <th scope="row">Treasurer</th>
            <td>Roman</td>
            <td>Laczkovich</td>
            <td><a href="mailto:roman.laczkovich@gmail.com">roman.laczkovich@gmail.com</a></td>
          </tr>

          <tr>
            <th scope="row">Membership Secretary</th>
            <td>Karen</td>
            <td>Childs</td>
            <td><a href="mailto:aussocmsec@yahoo.com">aussocmsec@yahoo.com</a></td>
          </tr>
          <tr>
            <th scope="row">Correspondence Secretary</th>
            <td>Regina</td>
            <td>Weiss</td>
            <td><a href="mailto:rnw4225@gmail.com">rnw4225@gmail.com</a></td>
          </tr>

          <tr>
            <th scope="row">Recording Secretary</th>
            <td>Karl</td>
            <td>Nagel</td>
            <td><a href="mailto:1970sedandeville@gmail.com">1970sedandeville@gmail.com</a></td>
          </tr>
   

        </tbody>
      </table>
<div class="my-4 border border-danger rounded text-center">
    <h6 class="mt-2">Trustees:</h6>
    <p> Dieter Busch, Philip Reich, Herbert Gruber, Roland Putz, Frank Dosenberger, Catherine McKay-Kitter</p>
</div>

  </div>

<div class="text-center">
    <figure>
    <img src="../../assets/img/Vorstand2019.jpg" alt="">
    <figcaption>Vorstand 2019</figcaption>
</figure>
</div>




</div>