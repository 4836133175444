<div class="text-center p-3">
    <h1>Newsletter Archive</h1>
</div>

<div class="text-center">
    <a href="../../assets/23 Sept Newsletter b.pdf">September 2023</a>
</div>

<div class="text-center m-4 links">
    <a href="../../assets/newsletter_08_22.pdf"><strong>August 2022</strong></a>
</div>

<div class="text-center m-4 links">
    <a href="../../assets/newsletter_02_2020 .pdf"><strong>February 2020</strong></a>
</div>


<div class="text-center m-4 links">
    <a href="../../assets/newsletter_10_2020.pdf"><strong>October 2020</strong></a>
</div>

<div class="text-center m-4 links">
    <a href="../../assets/newsletter_06_2021.pdf"><strong>June 2021</strong></a>
</div>