<div class="text-center p-3">
    <h1>Events</h1>
    <hr>
</div>





<div class="text-center container border border-primary rounded bg-light p-4 my-3">
    <div class="text-center photo">
        <img src="../../assets/img/ball.jpg" alt="">
    </div>

    <div class="text-center p-3">
        <h2>Strauss Ball</h2>
        <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus lacus orci, in accumsan lorem
                vulputate
                a. Aenean imperdiet ultricies dui, id pretium urna volutpat a. Sed purus est, malesuada at volutpat eu,
                malesuada a quam. Quisque vulputate rhoncus suscipit. Aliquam erat volutpat. Phasellus nibh mi,
                convallis id
                est eget, lacinia convallis tortor. In sit amet tellus dolor. Fusce vestibulum at lacus non congue. In
                purus
                magna, suscipit eu maximus nec, ornare nec leo. Donec id risus pretium, vestibulum elit ut, laoreet
                libero.
                Sed posuere posuere iaculis. Sed vitae finibus arcu.</p>

            <button class="btn btn-primary" href="#">More Info...</button>
        </div>
    </div>
</div>

<div class="text-center container border border-danger rounded bg-light p-4 my-3">
    <div class="text-center photo">
        <img src="../../assets/img/waldfest.jpg" alt="">
    </div>

    <div class="text-center p-3">
        <h2>Waldfest</h2>
        <div>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed tempus lacus orci, in accumsan lorem
                vulputate
                a. Aenean imperdiet ultricies dui, id pretium urna volutpat a. Sed purus est, malesuada at volutpat eu,
                malesuada a quam. Quisque vulputate rhoncus suscipit. Aliquam erat volutpat. Phasellus nibh mi,
                convallis id
                est eget, lacinia convallis tortor. In sit amet tellus dolor. Fusce vestibulum at lacus non congue. In
                purus
                magna, suscipit eu maximus nec, ornare nec leo. Donec id risus pretium, vestibulum elit ut, laoreet
                libero.
                Sed posuere posuere iaculis. Sed vitae finibus arcu.</p>

            <button class="btn btn-primary" href="#">More Info...</button>
        </div>
    </div>
</div>