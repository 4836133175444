import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ContactComponent } from './contact/contact.component';
import { EventsComponent } from './events/events.component';
import { GalleryComponent } from './gallery/gallery.component';
import { HistoryComponent } from './history/history.component';
import { LinksComponent } from './links/links.component';
import { NewsletterComponent } from './newsletter/newsletter.component';
import { ParkComponent } from './park/park.component';
import { SportsComponent } from './sports/sports.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CommitteeComponent } from './committee/committee.component';
import { MembersComponent } from './members/members.component';
import { MembershipComponent } from './membership/membership.component';
import { NewsarchiveComponent } from './newsarchive/newsarchive.component';
import { ParkrentalComponent } from './parkrental/parkrental.component';
import { Sb17Component } from './sb17/sb17.component';
import { Sb18Component } from './sb18/sb18.component';
import { Sb19Component } from './sb19/sb19.component';
import { WaldfestpicsComponent } from './waldfestpics/waldfestpics.component';
import { SkiclubpicsComponent } from './skiclubpics/skiclubpics.component';
import { AussocpicsComponent } from './aussocpics/aussocpics.component';


const routes: Routes = [
  {
    path:"", component: HomeComponent
  },
  {
    path:"history", component: HistoryComponent
  },
  {
    path:"committee", component: CommitteeComponent
  },
  {
    path:"members", component: MembersComponent
  },
  {
    path:"membership", component: MembershipComponent
  },
  {
   path:"events", component: EventsComponent 
  },
  {
    path:"calendar", component: CalendarComponent 
   },
  {
    path:"sports", component: SportsComponent
  },
  {
    path:"park", component: ParkComponent
  },
  {
    path:"parkrental", component: ParkrentalComponent
  },
  {
    path:"news", component:NewsletterComponent
  },
  {
    path:"newsarchive", component:NewsarchiveComponent
  },
  {
    path:"gallery", component:GalleryComponent
  },
  {
    path:"sb17", component:Sb17Component
  },
  {
    path:"sb18", component:Sb18Component
  },
  {
    path:"sb19", component:Sb19Component
  },
  {
    path:"waldfestpics", component:WaldfestpicsComponent
  },
  {
    path:"skiclubpics", component:SkiclubpicsComponent
  },
  {
    path:"aussocpics", component:AussocpicsComponent
  },
  {
    path:"links", component:LinksComponent
  },
  {
    path:"contact", component:ContactComponent
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
