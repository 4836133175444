<footer class="bg-dark text-white text-center text-lg-start">
    
    <div class="text-center copyright p-3">
      © 2021 Austrian Society, Detroit
      <div>
      <a href="https://www.facebook.com/Austrian-Society-of-Detroit-163720423717805/" class="fa fa-facebook"></a>
    </div>
    <div class="text-center design p-3">
      Web design: Carmen Gruber
    </div>
    </div>

    
   
  </footer> 

