import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sb18',
  templateUrl: './sb18.component.html',
  styleUrls: ['./sb18.component.css']
})
export class Sb18Component {
  currentIndex: any = -1;
  showFlag: any = false;
  
  imageObject: Array<object> = [
    {
      image: '../../assets/img/straussball/2018/pic1.jpg',
      thumbImage: '../../assets/img/straussball/2018/pic1.jpg',
      title: 'Straussball 2018'
  }, {
    image: '../../assets/img/straussball/2018/pic2.jpg',
    thumbImage: '../../assets/img/straussball/2018/pic2.jpg',
    title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic3.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic3.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic4.png',
  thumbImage: '../../assets/img/straussball/2018/pic4.png',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic5.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic5.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic6.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic6.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic7.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic7.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic8.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic8.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic9.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic9.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic10.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic10.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic11.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic11.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic12.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic12.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic13.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic13.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic14.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic14.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic15.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic15.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic16.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic16.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic17.jpg',
  thumbImage: '../../assets/img/straussball/2018/pic17.jpg',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic18.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic18.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic19.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic19.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic20.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic20.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic21.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic21.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic22.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic22.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic23.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic23.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic24.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic24.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic25.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic25.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic26.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic26.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic27.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic27.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic28.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic28.JPG',
  title: 'Straussball 2018'
},
{
  image: '../../assets/img/straussball/2018/pic29.JPG',
  thumbImage: '../../assets/img/straussball/2018/pic29.JPG',
  title: 'Straussball 2018'
}

];

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
