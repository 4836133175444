<div class="container">

<div class="text-center p-3">
    <h1>Our Clubhouse and Park</h1>


<div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner text-center">
      <div class="carousel-item active">
        <img class="w-50" src="../../assets/img/park_wedding.jpg" alt="First slide">
      </div>
      <div class="carousel-item">
        <img class="w-50" src="../../assets/img/Clubhouse12.jpg" alt="Second slide">
      </div>
      <div class="carousel-item">
        <img class="w-50" src="../../assets/img/park_woods.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="w-50" src="../../assets/img/SoccerField10.jpg" alt="Third slide">
      </div>
      <div class="carousel-item">
        <img class="w-50" src="../../assets/img/DanceFloor6.jpg" alt="Third slide">
      </div>
    </div>
      </div>
</div>


<!-- <div class="pics d-flex justify-content-around pl-2 ml-2">
    <div>
        <img src="../../assets/img/park_clubhouse.jpg" alt="">
    </div>

    <div>
        <img src="../../assets/img/park_wedding.jpg" alt="">
    </div>

    <div>
        <img src="../../assets/img/park_woods.jpg" alt="">
    </div>
</div> -->

<div class="container text-center p-3 content">
    <p>
        The Austrian Park is located at <b>56200 Hayes Road, Macomb Township.</b> This is on Hayes Rd. between 25 Mile and 26
        Mile Road.<br>

        Our park becomes increasingly popular as a site for weddings, graduation parties and office parties organized by
        local businesses.<br>
        For Information call Curt Kitter: 586-322-9226
    </p>



    <div class="text-center pt-3 pb-5">
        <div style="width: 100%"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=56200%20Hayes%20Road,%20Macomb%20County+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe></div>

        
            </div>
        </div>

    </div>