import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-skiclubpics', //update this component name when copy/pasting into new component//
  templateUrl: './skiclubpics.component.html',//update this component name when copy/pasting into new component//
  styleUrls: ['./skiclubpics.component.css']//update this component name when copy/pasting into new component//
})
export class SkiclubpicsComponent { //update this component name when copy/pasting into new component//
  currentIndex: any = -1;
  showFlag: any = false;
  
  imageObject: Array<object> = [
    {
      image: '../../assets/img/skiclub/pic1.jpg',
      thumbImage: '../../assets/img/skiclub/pic1.jpg',
      title: 'Ski Club'
  },
  {
    image: '../../assets/img/skiclub/pic2.jpg',
    thumbImage: '../../assets/img/skiclub/pic2.jpg',
    title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic3.jpg',
  thumbImage: '../../assets/img/skiclub/pic3.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic4.jpg',
  thumbImage: '../../assets/img/skiclub/pic4.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic5.jpg',
  thumbImage: '../../assets/img/skiclub/pic5.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic6.jpg',
  thumbImage: '../../assets/img/skiclub/pic6.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic7.jpg',
  thumbImage: '../../assets/img/skiclub/pic7.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic8.jpg',
  thumbImage: '../../assets/img/skiclub/pic8.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic9.jpg',
  thumbImage: '../../assets/img/skiclub/pic9.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic10.jpg',
  thumbImage: '../../assets/img/skiclub/pic10.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic11.jpg',
  thumbImage: '../../assets/img/skiclub/pic11.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic12.jpg',
  thumbImage: '../../assets/img/skiclub/pic12.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic13.jpg',
  thumbImage: '../../assets/img/skiclub/pic13.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic14.jpg',
  thumbImage: '../../assets/img/skiclub/pic14.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic15.jpg',
  thumbImage: '../../assets/img/skiclub/pic15.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic16.jpg',
  thumbImage: '../../assets/img/skiclub/pic16.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic17.jpg',
  thumbImage: '../../assets/img/skiclub/pic17.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic18.jpg',
  thumbImage: '../../assets/img/skiclub/pic18.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic19.jpg',
  thumbImage: '../../assets/img/skiclub/pic19.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic20.jpg',
  thumbImage: '../../assets/img/skiclub/pic20.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic21.jpg',
  thumbImage: '../../assets/img/skiclub/pic21.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic22.jpg',
  thumbImage: '../../assets/img/skiclub/pic22.jpg',
  title: 'Ski Club'
},
{
  image: '../../assets/img/skiclub/pic23.jpg',
  thumbImage: '../../assets/img/skiclub/pic23.jpg',
  title: 'Ski Club'
},

];

  showLightbox(index) {
    this.currentIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }
}
