
  
   <nav class="navbar navbar-expand-lg navbar-light bg-light mx-auto ml-3 mr-3">
       
       <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
         <span class="navbar-toggler-icon"></span>
       </button>
       <a class="navbar-brand" href="#"><img src="../../assets/img/ASD_Logo_nobackground (3).png" width="150" height="100"
        class="d-inline-block align-top" alt=""></a>
       <div class="collapse navbar-collapse" id="navbarNavAltMarkup">

         
      <ul class="navbar-nav">
        <li class="nav-item"> <a class="nav-link text-reset" routerLink="/"> Home </a> </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown"> About </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/history"> Club History </a></li>
            <li><a class="dropdown-item" routerLink="/committee"> Committee </a></li>

            <li><a class="dropdown-item" routerLink="/membership"> Membership </a>
              <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item" href="../../assets/membership_form.pdf"> Membership Application</a></li>
                <li><a class="dropdown-item" routerLink="/members"> Members</a></li>

              </ul>
              
            </li>


            <li><a class="dropdown-item"> Sub-groups of Austrian Society </a>
              <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item" routerLink="/sports"> Ski Club</a></li>
                <li><a class="dropdown-item" href="http://sportclub1924.com/"> Sport Club 1924 Soccer</a></li>




              </ul>
            </li>


          </ul>
        </li>

        <!-- <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown" routerLink="/events"> Events </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/events"> Strauss Ball </a></li>
            <li><a class="dropdown-item" routerLink="/events"> Waldfest </a></li>
            <li><a class="dropdown-item" routerLink="/events"> Seasonal Happenings </a></li>
            <li><a class="dropdown-item" routerLink="/calendar"> Event Calendar </a></li>



          </ul>
        </li> -->
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown"> Sports </a>
          <ul class="dropdown-menu">

            <li><a class="dropdown-item" routerLink= "/sports"> Ski & Golf </a>
              <!-- <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item" routerLink= "/sports"> Skiing Activities</a></li>
                <li><a class="dropdown-item" routerLink= "/sports">Golf</a></li>
                <li><a class="dropdown-item" routerLink= "/sports"> Ski Club Membership</a></li>



              </ul> -->
            </li>


            <li><a class="dropdown-item" href="http://sportclub1924.com/"> Sport Club 1924 Soccer </a>




          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown"> Austrian Park </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/park"> Overview Venue </a></li>
            <!-- <li><a class="dropdown-item" routerLink="/park"> Club House </a></li> -->
            <li><a class="dropdown-item" routerLink="/parkrental"> Park Rental </a></li>



          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown"> News </a>
          <ul class="dropdown-menu">
            <li><a class="dropdown-item" routerLink="/news"> Current Newsletter </a></li>
            <li><a class="dropdown-item" routerLink="/newsarchive"> Previous Newsletters </a></li>



          </ul>
        </li>

        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle text-reset" data-toggle="dropdown"> Gallery </a>
          <ul class="dropdown-menu">

            <li><a class="dropdown-item" routerLink="/aussocpics"> Austrian Society </a>
              <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item"> Strauss Balls</a>
                <ul class="submenu dropdown-menu">
                  <li><a class="dropdown-item" routerLink="/sb19">
                      2019
                    </a></li>
                    <li><a class="dropdown-item" routerLink="/sb18">
                      2018
                    </a></li>
                    <li><a class="dropdown-item" routerLink="/sb17">
                      2017
                    </a></li>

                </ul>
                
              </li>
                
                <li><a class="dropdown-item" routerLink="/waldfestpics"> Waldfeste</a></li>
                <li><a class="dropdown-item"> Austrian Society Historic Pics</a></li>


              </ul>
            </li>
            <li><a class="dropdown-item" routerLink="/skiclubpics"> Ski Club </a>
              <ul class="submenu dropdown-menu">
                <li><a class="dropdown-item"> Ski Outtings</a>
                  <ul class="submenu dropdown-menu">
                    <li><a class="dropdown-item">
                        2019
                      </a></li>
                      <li><a class="dropdown-item">
                        2018
                      </a></li>
                      <li><a class="dropdown-item">
                        2017
                      </a></li>
  
                  </ul>
                
                </li>
                <li><a class="dropdown-item"> Ski Club Historic Pics</a></li>


              </ul>
            </li>







          </ul>
        </li>

        <li class="nav-item"> <a class="nav-link text-reset" routerLink="/links"> Links </a> </li>
        <li class="nav-item"> <a class="nav-link text-reset" routerLink="/contact"> Contact </a></li>
      </ul>
       </div>
     </nav>




